import React from "react"
import TrackingForm from './trackingform'

const Hero = (props) => {
    return(
    <section className="text-gray-600 body-font homeHero pt-16 pb-16" style={{backgroundImage : `url(${props.content.primary.background_image.url})`, backgroundRepeat: 'no-repeat', backgroundPosition: `bottom right`, backgroundSize: `${props.content.primary.background_size} auto`}}>
        {/* {{backgroundImage : `url(${props.content.primary.section_background.url})`, backgroundRepeat: 'no-repeat', backgroundPosition: `bottom ${props.content.primary.bg_position}`, backgroundSize: `${props.content.primary.background_size} auto`}} */}
        {console.log(props)}
        <div className="container mx-auto flex flex-wrap px-5 py-24 md:flex-row flex-col items-center">
            <div className="md:w-3/5 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h1 className="title-font antialiased sm:text-6xl text-3xl mb-10 font-light  text-elite-dark">{props.content.primary.hero_title.text}</h1>
                <p className="mb-8 antialiased leading-relaxed text-gray-500">
                    {props.content.primary.hero_content.text}
                </p>
            </div>
            {props.parent === "Home" &&
                <TrackingForm />
            }
        </div>
    </section>
    )
}
  
  export default Hero 