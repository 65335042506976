import React, { Fragment, useRef, useEffect, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Dialog, Transition } from "@headlessui/react"

import lottie from 'lottie-web'
import delivered from '../../../static/lottie/check.json'
import History from '../parts/history'


const TrackingForm = (props) => {
    let [open, setOpen] = useState(false)
    let [hasResponse, setHasResponse] = useState(false)

    // Modal Values that will change with tracking details
    let [modalTitle, setModalTitle] = useState("Please wait while we check your package details.")
    let [hasHistory, setHistory] = useState("")

    const cancelButtonRef = useRef();
    let animationContainer = React.createRef()

    function closeModal() {
        setOpen(false);
    }
    
    function openModal() {
        setOpen(true);
    }

    function packageDelivered() {
        console.log(delivered)
        lottie.loadAnimation({
            container: animationContainer.current, // the dom element that will contain the animation
            autoplay: true,
            animationData: delivered,
        });
    }

    return(
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className={`inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${hasResponse?"max-w-4xl":"max-w-md"}`}>
                <div className="w-1/3 h-72 -mt-16 mx-auto border-b-2 border-elite-light border-opacity-30" ref={animationContainer} />
                <Dialog.Title
                  as="h3"
                  className="text-3xl font-medium leading-6 text-gray-900 -mt-16 mr-auto text-center"
                >
                  { modalTitle }
                </Dialog.Title>
                <div className="mt-2 flex flex-wrap">
                    <div className="w-1/3 container px-5 py-24 mx-auto flex flex-wrap">
                        General Details
                    </div>
                    <div className="w-2/3">
                        {hasHistory !== "" &&
                            <History items={hasHistory} />
                        }
                    </div>
                    {/* <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                        <p className="text-sm text-gray-500">
                            Your payment has been successfully submitted. We’ve sent
                            your an email with all of the details of your order.
                        </p>
                    </div> */}
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
        <Formik
            initialValues={{
                tracking_number: '',
                tracking_type: '',
            }}
            onSubmit={(values, actions) => {
                // alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
                
                // Axios Stuff here - copied from Postman
                var axios = require('axios');
                var qs = require('qs');
                var data = qs.stringify({
                    'waybill_no': values.tracking_number,
                    'tracktype': 'AWB' 
                });
                var config = {
                method: 'post',
                url: 'https://elite-co.com/eapi/shipment/trackorder',
                headers: { 
                    'ApiKey': '6QMO2vOvQW3tCE2v2MTqtIzTHEXs58EFTAW8NrogiT8=', 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
                };

                axios(config)
                .then(function (response) {
                    console.log(response.data)
                    setHasResponse(true)
                    response.data.data.shipments.map(shipment => (
                        setModalTitle(shipment.status),
                        setHistory(shipment.history)  
                    ))
                    packageDelivered()
                })
                .catch(function (error) {
                console.log(error);
                });

            }}
            validate={values => {
                // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const errors = {};
                if(!values.tracking_number) {
                    errors.tracking_number = 'Tracking Number Required'
                }
                if(!values.tracking_type) {
                    errors.tracking_type = '<< Please select Tracking type'
                }
                return errors;
            }}
        >
            <Form className="flex flex-wrap w-3/4 md:justify-start justify-center items-end">
                <div className="relative mr-4 w-full md:w-full mb-4" role="group" aria-labelledby="tracking-group">
                    <label className="mr-10">
                        <Field type="radio" name="tracking_type" value="AWB" className="mr-2" />
                        Airway Bill
                    </label>
                    <label className="mr-10">
                        <Field type="radio" name="tracking_type" value="SHP" className="mr-2" />
                        Reference Number
                    </label>
                    <div className="inline-flex text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="tracking_type" /></div>
                </div>
                <div className="flex flex-wrap w-3/4">
                    <div className="mr-4 md:w-1/2 -ml-4">
                        <Field 
                            name="tracking_number" 
                            id="tracking_number" 
                            type="text" 
                            className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Enter Airway Bill / Reference Number" 
                        />
                        <div className="text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="tracking_number" /></div>
                    </div>
                    <button onClick={openModal} className="h-11 px-10 py-2 text-white bg-elite-dark focus:outline-none hover:bg-elite-light hover:text-elite-dark transition duration-75 rounded-full text-md uppercase">Track</button>
                </div>
            </Form>
        </Formik>
    </>
    )
}
  
export default TrackingForm