import * as React from "react"
// import PropTypes from "prop-types"
// import { withPrefix, Link } from "gatsby"
// import { Helmet } from "react-helmet"

const Section = (props) => (
    <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg">
                <img alt="feature" className="object-cover object-center w-full -ml-24 scale-150" src={props.content.primary.image.url} />
            </div>
            <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left">
                <div className="flex flex-wrap w-full mb-20 flex-col">
                    <h5 className="sm:text-base text-lg uppercase font-normal title-font mb-2 text-elite-light">{props.content.primary.section_name}</h5>
                    <h2 className="sm:text-4xl text-4xl font-extralight title-font mb-2 text-gray-900">{props.content.primary.section_title.text}</h2>
                    <p className="leading-relaxed text-gray-500">{props.content.primary.content.text}</p>
                </div>
                <div className="flex flex-wrap -m-4">
                    {props.content.items.map(item => (
                        <div className="xl:w-1/3 md:w-1/2 p-4 -ml-12" id={item.stat_icon.url}>
                            <div className="p-6 rounded-2xl">
                                <div className="w-24 h-24 rounded-full bg-indigo-100 text-indigo-500 mb-4 mx-auto">
                                    <img className="mx-auto w-24 h-24" src={item.stat_icon.url} alt="" />
                                </div>
                                <h2 className="text-2xl text-elite-dark font-bold title-font text-center">{item.key_fact}</h2>
                                <p className="leading-relaxed text-base text-center">{item.fact_measurement}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <a href="../aboutus" className="flex mr-auto mt-10 text-white bg-elite-dark rounded-full border-0 py-2 px-8 focus:outline-none hover:bg-elite-light text-base font-light uppercase">Learn more about us</a>
            </div>
        </div>
    </section>
  )
  
  export default Section