import React from "react"

const halfImagehalfContent = (props) => {
    return(
    <section className="text-gray-600 body-font homeHero pt-16 pb-10" style={{backgroundImage : `url(${props.content.primary.image.url})`, backgroundRepeat: 'no-repeat', backgroundPosition: `bottom ${props.content.primary.image_side}`, backgroundSize: `${props.content.primary.image_size} auto`}}>
        <div className={`container mx-auto flex px-5 py-24 flex-col items-center ${(props.content.primary.image_side==="Left")?"md:flex-row":"md:flex-row-reverse"}`}>
            {console.log(props.content.primary.image_side)}
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                {/* <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center ml-20">
                <h5 className="sm:text-base text-lg uppercase font-normal title-font mb-10 text-elite-light">{props.content.primary.section_name}</h5>
                <h2 className="sm:text-4xl text-4xl font-extralight title-font mb-20 mr-28 text-elite-dark">{props.content.primary.section_title.text}</h2>
                <p className="leading-relaxed text-gray-500">{props.content.primary.section_content.text}</p>
                {/* <div className="flex justify-center">
                    <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                    <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                </div> */}
            </div>
        </div>
    </section>
    )
}
  
export default halfImagehalfContent 