import React from "react"

const History = (props) => {
    return(
        <section className="text-gray-600 body-font">
            {console.log(props)}
            <div className="container px-5 py-24 mx-auto flex flex-wrap">
                <div className="flex flex-wrap w-full">
                    <div className="md:w-full md:pr-10 md:py-6">
                    {props.items.map(item => (
                        <div className="flex relative pb-12">
                            <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                            </div>
                            <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>
                            </div>
                            <div className="flex-grow pl-4">
                                <div className="flex">
                                    <h5 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider mr-10">Date: {item.date}</h5>
                                    <h5 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Time: {item.time}</h5>
                                </div>
                            <p className="leading-relaxed">{item.remark}</p>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
  
  export default History 