import * as React from "react"

const Section = (props) => (
    <section className="text-gray-600 body-font" style={{backgroundImage : `url(${props.content.primary.section_background.url})`, backgroundRepeat: 'no-repeat', backgroundPosition: `bottom ${props.content.primary.bg_position}`, backgroundSize: `${props.content.primary.background_size} auto`}}>
        <div className="container px-5 py-24 mx-auto">
            <div className={`flex flex-wrap w-full mb-20 flex-col ${(props.alignment === "Left")?"ml-24":"items-center text-center"}`}>
                {props.content.primary.section_name !== "" &&
                    <h5 className="sm:text-base text-lg uppercase font-normal title-font mb-10 text-elite-light">{props.content.primary.section_name}</h5>
                }
                {props.content.primary.section_title.text &&
                    <h2 className="sm:text-4xl text-4xl font-extralight title-font mb-20 text-gray-900">{props.content.primary.section_title.text}</h2>
                }
                {props.content.primary.section_content.text &&
                    <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">{props.content.primary.section_content.text}</p>
                }
            </div>
            {props.content.items[0].iwt_title.text !== "" &&
            <div className={`flex flex-wrap -m-4 mb-32 ${(props.alignment === "Left")?"ml-24 w-2/3":"mx-auto w-2/3"}`}>
                {props.content.items.map(item => (
                    <div key={item.iwt_image.url} className={`${props.content.primary.icon_columns === "4"?"xl:w-1/4 sm:w-1/2":"xl:w-1/3 md:w-1/2"} ${(props.alignment === "Left")?"":"p-8"}`}>
                        <div className="rounded-2xl">
                            {item.iwt_image.url !== null &&
                                <div className={`w-24 h-24 rounded-full bg-indigo-100 text-indigo-500 mb-4 ${(props.alignment === "Left")?"-ml-4":"mx-auto"}`}>
                                    <img className="mx-auto w-24 h-24" src={item.iwt_image.url} alt={item.iwt_image.alt} />
                                </div>
                            }
                            <h2 className={`text-elite-dark font-medium title-font ${(item.iwt_image.url === null)?"text-4xl":"text-lg mb-8"} ${(props.alignment === "Left")?"":"text-center"}`}>{item.iwt_title.text}</h2>
                            <p className={`leading-relaxed text-base ${(props.alignment === "Left")?"":"text-center"}`}>{item.iwt_content.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            }
            {props.content.primary.button_text !== null &&
                <>
                    <button className="flex mx-auto mt-16 text-white bg-elite-dark rounded-full border-0 py-2 px-8 focus:outline-none hover:bg-elite-light text-base font-light uppercase">{props.content.primary.button_text}</button>
                </>
            }
        </div>
    </section>
  )
  
  export default Section